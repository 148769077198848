import React from "react"
import { ThemeContext } from "../provider.js"
import styles from "./theme-toggle.module.css"
import { FaMoon, FaSun } from "react-icons/fa"

export default function ThemeToggle() {
  return(
    <ThemeContext.Consumer>
      {context => (
        <button onClick={() => context.changeTheme()} className={styles.toggle} title="Toggle theme">
          <span className={[context.isDark ? styles.active : "", styles.dark].join(" ")}>
            <FaMoon />
          </span>
          <span className={[context.isDark ? "" : styles.active, styles.light].join(" ")}>
            <FaSun />
          </span>
        </button>
      )}
    </ThemeContext.Consumer>
  )
}
