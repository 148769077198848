import React from "react"
import styles from "./footer.module.css"
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa"

export default function Footer() {
  return (
    <footer>
      <div className="flex maxWidth">
        <div>
          <a href="https://github.com/ericanorby" rel="noreferrer noopener" target="_blank" className={styles.link} title="GitHub profile">
            <FaGithub className={styles.icon} />
          </a>
          <a href="https://www.linkedin.com/in/erica-norby-50013b9b/" rel="noreferrer noopener" target="_blank" className={styles.link} title="LinkedIn profile">
            <FaLinkedin className={styles.icon} />
          </a>
        </div>
        <div className={styles.copyright}>
          &copy; {new Date().getFullYear()} Erica Norby
        </div>
      </div>
    </footer>
  )
}
